.register-form{
  padding-left: 10%;
}
/* Default styles */
.form-content {
  width: 55%;
  border: 2px solid black;
  border-radius: 30px;
  padding: 3%;
  border: 1px solid #ced4da;
  margin-bottom: 2%;
  background-color: #FFFFFF;
  margin-left: 0 !important;
}

.form-control {
  border-radius: 1.5rem;
}

.btnSubmit {
  border: none;
  border-radius: 1.5rem;
  padding: 1%;
  width: 100%;
  cursor: pointer;
  background: #000000;
  color: #fff;
}

label {
  font-size: 17px;
  font-weight: bold;
}

/* Media query for smaller screens (phones) */
@media (max-width: 576px) {
  .form-content {
      width: 90%; /* Adjust width for smaller screens */
      padding: 5%; /* Increase padding for better spacing on smaller screens */
      font-size: 14px; /* Decrease font size for better readability */
      margin-left: 20px;
      margin-top: 90px;
  }

  .form-control {
      /* Adjust any other necessary styles for form controls */
  }

  .btnSubmit {
      /* Adjust any other necessary styles for the submit button */
  }

  label {
      font-size: 14px; /* Decrease label font size for smaller screens */
  }
}

a {
text-decoration: none !important; /* Remove underline */
}
.buy{
  color: #bcff00;
  background-color: #000000;
}
.about-link h5 {
color: #000; /* Set initial text color */
transition: background-color 0.3s ease; /* Smooth transition for background color change */
}
.about-linkk h5 {
color: #000; /* Set initial text color */
transition: background-color 0.3s ease; /* Smooth transition for background color change */
}

.about-linkk:hover h5 {
color: #bcff00; /* Change background color on hover */
}
/* .title
{
user-select: none;
color:white;
padding:20px;
} */

/* ------------------------------- */
/* .tit{
color: #ffffff;
font-size: 20px;
} */
@media (max-width: 768px) {
  .about-link  {
      position: static; /* Reset position */
      margin-left: auto; /* Set margin-left to auto to push to the right */
      display: block; /* Ensure it's a block-level element */
      text-align: right; /* Align the content to the right */
      
  }
 
  .uk{
    margin-top: -50px; /* Example of setting a negative margin on the top side */
  }
 .ma{
  margin-top: 30px;
 }
}

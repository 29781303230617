@media (max-width: 768px) {
  .image  {
  width: 300px;
  height: auto; /* Maintain aspect ratio */
  margin-left:-80px; /* Remove left margin */
  }
  }
  .custom-button {
    margin: 0;
    background-color: "#071A44";
    color: "#FFFFFF";
  }
  .ai{
    background-color:white;border-radius: 5%; height: 800px;
  }
  .hi{
    width: 300px;height: 300px ;margin-left: 80px;
  }
  
  .bod{
    background-color: #fff;
    box-shadow: 0 5px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(110, 110, 110, 0.22);
  }
  .gg{
    color: black;
    font-weight: bold;
  }
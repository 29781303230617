.mobile-pic {
    border: 1px solid #071A44; border-radius: 13.794px;
    display: flex;
    justify-content: center;
    align-items: center;
    }






.buy{
    color: #bcff00;
    background-color: #000000;
  }

/* Style for website title */
.title
{
  user-select: none;
  color:white;
  padding:20px;
}  a {
  text-decoration: none !important; /* Remove underline */
}
.navbar{
        background-color: #bcff00;
    }
    .about-link h5 {
  color: #000; /* Set initial text color */
  transition: background-color 0.3s ease; /* Smooth transition for background color change */
}
.about-linkk h5 {
  color: #000; /* Set initial text color */
  transition: background-color 0.3s ease; /* Smooth transition for background color change */
}

.about-linkk:hover h5 {
  color: #bcff00; /* Change background color on hover */
}
/* ---------------- */

@media (max-width: 768px) {
    .about-link  {
        position: static; /* Reset position */
        margin-left: auto; /* Set margin-left to auto to push to the right */
        display: block; /* Ensure it's a block-level element */
        text-align: right; /* Align the content to the right */
        
    }
   
    .uk{
      margin-top: -50px; /* Example of setting a negative margin on the top side */
    }
   .ma{
    margin-top: 30px;
   }
}


/* For screens smaller than 768px (typical phone screens) */
@media (max-width: 768px) {
  .upper-section {
    flex-direction: column; /* Stack items vertically */
    align-items: center; /* Center align items */
    text-align: center; /* Center text */
  }
  
.ali{
    
}
.leads{
    width: 100% !important;
}
  .mobile-pic {
    /* Add space between the elements */
  }
  .leads{
    width: 10%;
  }

  .mobile-specs {
    text-align: center; /* Center text */
    margin-right: 0; /* Remove right margin */
  }
  
  /* Adjust specific styles as needed for smaller screens */
  .mobile-pic img {
    width: 100%; /* Make the image fill its container */
  }
}
@media (max-width: 576px) {
    .search-box {
        margin-left: 0;
        text-align: center; /* Center-align contents on smaller screens */
    }
   .smcarde {
    /* display: flex; */
    justify-content: center;
    align-items: center;
    width: 300px;
    /* margin-left: 50px; */
}
.tit{
  color: #ffffff;
font-size: 20px;
}
@media (max-width: 768px) {
    .about-link  {
        position: static; /* Reset position */
        margin-left: auto; /* Set margin-left to auto to push to the right */
        display: block; /* Ensure it's a block-level element */
        text-align: right; /* Align the content to the right */
        
    }
   
    .uk{
      margin-top: -50px; /* Example of setting a negative margin on the top side */
    }
   .ma{
    margin-top: 30px;
   }
}


}